import React from 'react'
import logo from '../assets/images/B_IG_Logo_Stack_Rev_RGB.svg'

const Header = () => (
	<div className="top-navigation">
		<div className="background">
				<div className="container grid">
				<div className="main-nav-logo">
					<a className="footerlinks" href='https://www.bloombergindustry.com/' className='headerLinkStyle'>
							<img src={logo} alt="Bloomberg Industry Group" style={{height: '60px', width: '260px', marginTop: '10px'}}/>
					</a>
				</div>
				<div className="main-nav-menu hide-mobile">
					<ul className="list-inline">
						<li className="list-inline-item">
							<a href='https://www.bloombergindustry.com/events/'>
							<span className="label">Events</span>
							</a>
						</li>
						<li className="list-inline-item">
							<a href='https://www.bloombergindustry.com/about-us/'>
							<span className="label">About Us</span>
							</a>
						</li>
						<li className="list-inline-item">
							<a href='https://www.bloombergindustry.com/careers/'>
							<span className="label">Careers</span>
							</a>
						</li>
						<li className="list-inline-item">
							<a target="_blank" href='https://www.bloombergindustry.com/contact-us/'>
							<span className="label">Contact Us</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
) 

export default Header