import React from 'react'
import crosshatch from '../assets/images/crosshatch.jpg'

export default class Banner extends React.Component {
    render () {
        return (
            <div>
                <br/><br/>
                <div className='banner'
                    //Adding the payment/.. is a little hacky but works on S3 and local
                    style={{backgroundImage: 'url("payment/..' + crosshatch +'")'}}>
                    <div className='.hide-mobile' style={{display: "inline-block", backgroundColor: "transparent", minWidth: "280px"}}></div>
                    <div className='text' style={{display: "inline-block", backgroundColor: "transparent"}}>
                        {this.displayText()}
                    </div>
                </div>
                <div className='after'></div>
            </div>
        )
    }
    displayText = () => (this.props.inCheckout ? 'Checkout' : 'Pay Your Invoice')
}

