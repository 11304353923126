import React from 'react'
import HelpText from './help/HelpText'

const ZeroBalance = () => (
    <div> 
        <div className='zeroTitle'>Zero Balance Due</div>
        There is currently no open balance on this invoice.<br/>
        This may be because the invoice is already paid in full<br/>
        or the invoice is not available to be paid through this site.<br/>
        <br/>
        For more information, please contact
        <HelpText/>.
	</div>
) 

export default ZeroBalance