import React from 'react'
import { Helmet } from 'react-helmet';
import Header from './Header'
import Footer from './Footer'
import Banner from './Banner'
import Controls from './Controls'
import Message from './Message'
import Progress from './Progress'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import jwt from 'jwt-decode'
import HelpText from './help/HelpText'
import {LAMBDA_PAYMENT_SUCCESS, LAMBDA_PAYMENT_SUCCESS_APPLY_FAIL, CURRENCY_REGEX, MESSAGE_TOKEN_TIME_OUT} from './Constants'
import  {STRIPE_SERVER_SIDE_VALIDATION_ERRORS} from './messages/StripeErrors'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.STRIPE_PUBLISH_KEY);
const turnOffIndicator = 'TURNOFF-';
let stripe = Stripe(process.env.STRIPE_PUBLISH_KEY);
let inPaymentProcessing = false;
let timerId = undefined;

const GENERIC_ERROR_TITLE = 'Processing Error'
const GENERIC_ERROR_MSG = <span>There was an issue processing your request.
                                <br/>Please refresh the page and try again.
                                <br/><br/>If the problem persists, please contact<HelpText/>.
                            </span>

const INVOICE_ERROR_MSG = <span>There was an issue retrieving your invoice information.
                                <br/>Please refresh the page and try again.
                                <br/><br/>If the problem persists, please contact<HelpText/>.
                            </span>

const PAYMENT_ERROR_MSG = <span>There was an issue processing your payment.
                                <br/>Please refresh the page and try again.
                                <br/><br/>If the problem persists, please contact<HelpText/>.
                            </span>

const TIMEOUT_ERROR_MSG = <span>Your session has timed out.
                                <br/>Please complete your credit card payment within
                                <br/>5 minutes of retrieving your invoice details.
                            </span>

const FIVE_MINUTES_IN_MILLIS = 300000;

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
export default class PaymentApp extends React.Component {
    state = {
        customerId: undefined,
        invoiceNum: undefined,
        invoiceAmount: undefined,
        subInvoiceNum: undefined,
        amountDue: undefined,
        amountCanPay: undefined,
        paymentAmount: undefined,
        companyCode: undefined,
        paymentAllowance: undefined,
        expTime: undefined,
        outOfSync: false,
        paid: false,
        inInvoice: false,
        inCheckout: false,
        jwToken: undefined,
        paymentMethod: undefined,
        paymentIntentId: undefined,
        token: undefined,
        email: undefined,
        name: undefined,
        lastPaymentInfo: undefined,

        //Dialog Message Properties
        showMessage: false,
        messageTitle: 'Payment Processing Issue',
        messageBody: 'An error occurred.  Please try again later.',
        messageType: 'error',
        shouldResetOnError: false,
        turnItOff: false,

        //Progress Spinner
        inProgress: false,
    }
    render() {
        return (
            <Elements stripe={stripePromise}>
                    <div>
                        <Helmet>
                            <script src={process.env.ADOBE_LAUNCH_HEADER}></script>
                        </Helmet>
                        {this.state.inProgress ? <Progress className='absolute'/> : ""}
                        <div className='wrapper'>
                            <div className='content'>
                                <Header/>
                                <Banner inCheckout={this.state.inCheckout}/>
                                {this.getControls()}
                            </div>
                        </div>
                        <Footer/>
                        <Message 
                            isOpen={this.state.showMessage}
                            title={this.state.messageTitle}
                            message={this.state.messageBody}
                            type={this.state.messageType}
                            handleCloseModal={this.handleCloseModal}
                            lockDialog={this.state.turnItOff}>
                        </Message>
                        <script type="text/javascript">_satellite.pageBottom();</script>
                    </div>
            </Elements>
        )
    }

    componentDidMount() {
        window.addEventListener("load", function(){
            // Handler when the DOM is fully loaded
            _satellite.track('pageLoaded');
        });
        this.getBroadcast();
    }

    handleCloseModal = () => {
        if (this.state.shouldResetOnError) {
            this.reset()
        }
        else {
            this.setState(() => ({showMessage: false}))
        }
    }

    //Do a couple checks to give last payment a chance to apply to mulesoft/SAP
    checkSyncStatus = (invoiceNum, remainingAmount, expectedBalance) => {
        console.log(`Expected from service: ${expectedBalance}`);

        console.log(this.state.lastPaymentInfo)
        //If we just now made a payment to same invoice us that expectation
        if (this.state.lastPaymentInfo && (this.state.lastPaymentInfo.invoiceNum == invoiceNum)) {
            expectedBalance = this.state.lastPaymentInfo.expectedBalance;
        }
        console.log(`Expected adjusted: ${expectedBalance}`);
        return expectedBalance && parseFloat(remainingAmount) > parseFloat(expectedBalance);
    }

    getControls() {
        return (
            <Controls 
                search = {this.search}
                inInvoice = {this.state.inInvoice}
                inCheckout = {this.state.inCheckout}
                customerNum = {this.state.customerId}
                invoiceNum = {this.state.invoiceNum}
                invoiceAmount = {this.state.invoiceAmount}
                outOfSync = {this.state.outOfSync}
                enterCheckout = {this.enterCheckout}
                paidInvoice = {this.paidInvoice}
                amountDue = {this.state.amountDue}
                paymentAllowance = {this.state.paymentAllowance}
                amountCanPay = {this.state.amountCanPay}
                paymentAmount = {this.state.paymentAmount}
                reset = {this.reset}
                paid = {this.state.paid}
                savePaymentMethod = {this.savePaymentMethod}
                showMessage = {this.showMessage}
                makePayment = {this.makePayment}
                furtherProcessing = {this.furtherProcessing}
                adobeDataLayer = {this.adobeDataLayer}
            />
        )
    }

    getValidationUrl() {
        return `${process.env.PAYMENT_GATEWAY_HOST}/indg/payment/generate-token`;
    }

    getPaymentUrl() {
        return `${process.env.PAYMENT_GATEWAY_HOST}/indg/payment/invoices/${this.state.invoiceNum}/makePayment`
    }

    //Perform the search for invoice
    search = (customer, invoice, amount) => {

        if (!customer || !invoice || !amount) {
            const mess = 'Please enter Bill To Number, Invoice Number and Invoice Amount'
            this.showMessage("Enter Search Criteria", mess, 'help');
            this.adobeDataLayer('Invoice Search', mess, 'Search')
            return
        }

        //Check for a valid Dollars and Cents value
        if (!CURRENCY_REGEX.test(amount)) {
            const mess = 'Please include dollars and cents, for example, 99.00'
            this.showMessage('Search Criteria', mess, 'help')
            this.adobeDataLayer('Invoice Search', mess, 'Search')

            return
        }

        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"InvoiceValidationObject":{"amount": amount,"customerNum": customer,"invoiceNum": invoice}})
        };

        this.setState(() => ({inProgress: true}))
        try {
            fetch(this.getValidationUrl(), options)
            .then((res) => {
                this.setState(() => ({inProgress: false}))
                return res.json()
                },
                (error) => {
                    this.setState(() => ({inProgress: false}))
                    throw error
                })
                .then((result) => {
                    console.log(result)
                    let invoiceData = {}
                    try {
                        let currencyRemaingAllowance = null;
                        if (result.statusCode == 200) {
                            invoiceData = jwt(result.token)
                            console.log(invoiceData)
                            //Remove any calculated precision error in allowance
                            currencyRemaingAllowance = (Math.round(invoiceData.remainingAllowance * 100))/100;
                            const remainingAmount = parseFloat(invoiceData.remainingAmount);
                            const canPay = remainingAmount > currencyRemaingAllowance ? currencyRemaingAllowance : remainingAmount
                            const outOfSync = this.checkSyncStatus(invoiceData.invoiceNum, invoiceData.remainingAmount, invoiceData.expectedBalance) ;
                            this.setState(() => ({
                                    companyCode: invoiceData.companyCode,
                                    customerId : invoiceData.customerNum, 
                                    invoiceNum: invoiceData.invoiceNum,
                                    subInvoiceNum: invoiceData.subInvoiceNum,
                                    invoiceAmount: invoiceData.amount, 
                                    amountDue: invoiceData.remainingAmount, 
                                    remainingAllowance: currencyRemaingAllowance,
                                    expTime: invoiceData.exp + (60 * 15),
                                    amountCanPay: canPay,
                                    outOfSync: outOfSync,
                                    token: result.token}))
                        }
                        this.invoiceFound(invoiceData.remainingAmount, currencyRemaingAllowance)
                    }
                    catch (e) {
                        console.log('Excpetion parsing validation response: ', e)
                        this.showMessage(GENERIC_ERROR_TITLE, INVOICE_ERROR_MSG, 'error')
                        this.adobeDataLayer('Invoice Search', 'Exception parsing validation response', 'Search')
                    }
                },
                (error) => {
                    console.log('Could not marshall response to json: ', error)
                    this.showMessage(GENERIC_ERROR_TITLE, INVOICE_ERROR_MSG, 'error')
                    this.adobeDataLayer('Invoice Search', 'Error fetching MuleSoft Validation', 'Search')
                }
            )
        }
        catch (e) {
            this.setState(() => ({inProgress: false}))
            console.log('Unexpected exception:', e)
            this.showMessage(GENERIC_ERROR_TITLE, INVOICE_ERROR_MSG, 'error')
            this.adobeDataLayer('Invoice Search', 'There was an exception retrieving invoice information', 'Search')
        }
    }

    //Update the view if we successfully searched for invoice
    invoiceFound = (amount, allowance) => {
       
        //If we found the invoice we will have a remaining amount
        if (amount == 0) {
            this.setState(() => ({inInvoice: true}))
            this.adobeDataLayer('Invoice Validation', 'Invoice Found. Amount is 0','Invoice Retrieval', true);
        }
        else if (amount) {
            amount = parseFloat(amount) > allowance ? allowance: amount;
            this.setState(() => ({inInvoice: true, paymentAllowance: allowance}))
            this.adobeDataLayer('Invoice Validation', 'Invoice Found','Invoice Retrieval', true);
            timerId = setTimeout(this.timeItOut, FIVE_MINUTES_IN_MILLIS);
            return true;
        }
        else {
            this.setState(() => ({inInvoice: false}))
            const mess = <span>Could not find an invoice with the data you entered.&nbsp;&nbsp;Please try again.
                            <br/><br/>If you need assistance, please contact <HelpText/>.
                        </span>      
            this.showMessage("Enter Valid Search Criteria", mess, 'help');
            this.adobeDataLayer('Invoice Validation', 'Invoice Not Found', 'Invoice Retrieval')
        }
    }

    showMessage = (title, message, type) => {
        this.setState(
                {showMessage: true, messageTitle: title, messageBody: message, messageType: type},
                this.focusOnDialogButton
            )
    }

    focusOnDialogButton() {
        document.getElementsByClassName('secondary')[0].focus();
    }

    //Called When user submits their payment amount
    enterCheckout = (payment) => {
        //show checkout page
        try {
            this.setState(() => ({inInvoice: false, inCheckout: true, paymentAmount : parseFloat(payment)}));
            this.adobeDataLayer('Checkout', 'Checkout Landing','Checkout', true)
        }
        catch (e) {
            console.log(e);
            this.showMessage(GENERIC_ERROR_TITLE, GENERIC_ERROR_MSG, 'error');
            this.adobeDataLayer('Checkout', 'There was an issue processing your request','Checkout')
        }
    }

    //Called after successful CC paymnet
    paidInvoice = () => {
        
        //Caluclate expected balance in cents to avoid precision errors
        const remainingCents = Math.round(parseFloat(this.state.amountDue) * 100);
        const paymentCents = Math.round(parseFloat(this.state.paymentAmount) * 100);
        const expectedBalance = ((remainingCents - paymentCents)/100).toString();
        console.log(`Expected balance: ${expectedBalance}`)
        
        //Save the last payment for sanity check on next invoice validation
        const lastPaymentInfo = {invoiceNum: this.state.invoiceNum, expectedBalance: expectedBalance}
        this.setState(() => ({paid: true, shouldResetOnError: false, lastPaymentInfo: lastPaymentInfo}));
        
        const mess = 'Thank you for your payment. You will receive an email with your payment confirmation.'
        this.showMessage("Payment Successful", mess, 'success')
        this.adobeDataLayer('Payment','Payment Success', 'Payment Confirmation')
    }

    //Completely reset the app to start state
    reset = () => {
        this.setState(() => ({showMessage: false, 
                                inInvoice: false, 
                                inCheckout: false,
                                inPaymentProcessing: false,
                                paid: false, 
                                shouldResetOnError: false}))
        this.adobeDataLayer('reset', 'click', 'Payment');
        clearTimeout(timerId);
        PAY_CLICKED = false;
    }

    savePaymentMethod = (id) => {
        this.setState(() => ({paymentMethod: id}))
    }

    //This is the method that handles secondary Stripe validation for 3D Secure, etc
    handleStripeJsResult = async (result) => {
        try {
            console.log("wrap up further processing")
            console.log(result);
            if (result.error) {
                this.setState(() => ({shouldResetOnError: false}))
                this.showMessage("Credit Card Error", 'Could not validate credit card security info.', 'info')
                PAY_CLICKED = false;
                return;
            }
            this.setState(() => ({paymentIntentId: result.paymentIntent.id}))
            
            console.log("Submit final payment")
            const makePaymentResult = await this.makePayment(this.state.paymentMethod, result.paymentIntent.id, this.state.email, this.state.name)
            console.log('result from final pay: ', makePaymentResult)
            
            if (makePaymentResult.status == LAMBDA_PAYMENT_SUCCESS || makePaymentResult.status == LAMBDA_PAYMENT_SUCCESS_APPLY_FAIL) {
                this.paidInvoice();
            }
            else {
                PAY_CLICKED = false;
                if (makePaymentResult.message == MESSAGE_TOKEN_TIME_OUT) {
                    //The timeOut method called inside makePayment will take care of showing a message and resetting
                    return;
                }
                if (STRIPE_SERVER_SIDE_VALIDATION_ERRORS.includes(makePaymentResult.message)) {
                    this.setState(() => ({shouldResetOnError: false}))
                    this.showMessage("Could Not Process Payment", makePaymentResult.message, 'info');
                    return;
                }
                this.showMessage(GENERIC_ERROR_MSG, PAYMENT_ERROR_MSG, 'error')
                this.adobeDataLayer('Payment','Payment Processing Failure', 'Payment')
            }
        }
        catch (e) {
            console.log('Excpetion handling credit card security: ', e)
            this.showMessage(GENERIC_ERROR_TITLE, PAYMENT_ERROR_MSG, 'error');
            this.adobeDataLayer('Payment','Payment Processing Failure', 'Payment');
            PAY_CLICKED = false;
        }
        
    }

    furtherProcessing = (clientSecretId) => {
        console.log("Begin further processing")
        console.log(clientSecretId);
        try {
            stripe.handleCardAction(
                clientSecretId
            ).then(this.handleStripeJsResult);
        }
        catch (e) {
            console.log(e);
            const mess = <span>There was an issue processing your credit card payment.
                            <br/>Please refresh the page and try again.
                            <br/><br/>If you need assistance, please contact <HelpText/>.
                        </span>   
            this.showMessage('Payment Error', mess, 'error');
        }
    }

    makePayment = async (paymentMethodId, paymentIntentId, email, name) => {
        try{
            this.setState(() => ({email: email, name: name}))
            const urlMakePayment = this.getPaymentUrl()
            console.log("urlMakePayment: ", urlMakePayment)
            const payload = {
                "InvoiceDataObject": {
                    "paymentMethodId": paymentMethodId, 
                    "paymentIntentId": paymentIntentId,
                    "paymentAmount": this.state.paymentAmount,
                    "invoiceAmount": this.state.invoiceAmount,
                    "remainingAmount": this.state.amountDue,
                    "customerNum": this.state.customerId,
                    "invoiceNum": this.state.invoiceNum,
                    "subInvoiceNum": this.state.subInvoiceNum,
                    "companyCode": this.state.companyCode,
                    "email": email,
                    "name": name
                }
            }

            const options = {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorizationToken': `Bearer ${this.state.token}`
                },
                body: JSON.stringify(payload)
            }
            console.log(options);

            //Check for timeout with an extra five seconds to reduce chance of timeout during process
            const now = Math.floor(Date.now()/1000); // Unix timestamp in seconds
            if ((now + 5) >= this.state.expTime) {
                this.timesUp();
                return {
                    "status": 0,
                    "message": MESSAGE_TOKEN_TIME_OUT 
                }
            }
            
            inPaymentProcessing = true;
            this.setState(() => ({inProgress: true, shouldResetOnError: true}))
            const response = await fetch(urlMakePayment, options);
            return await response.json();
        
        } catch(e) {
            return {
                "status": 0,
                "message": e.toString()    
            }
        } finally {
            this.setState(() => ({inProgress: false}));
        }
    }

    /** Makes a Lambda call to AWS params to see if it should display a message at startup.
     * If message does exist, and it begins with TURNOFF- a diolog will blog user from making payments
     */
    getBroadcast = async () => {

        try {
            const url = `${process.env.PAYMENT_GATEWAY_HOST}/indg/payment/broadcastMessage`
        
            const options = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            }

            const fetched = await fetch(url, options);
            const response = await fetched.json();
            let message = response.message;
            console.log(`Message:` + JSON.stringify(message));
            let turnItOff = false;
           
            if (!!message) {
                if (message.startsWith(turnOffIndicator)) {
                    message = message.substring(turnOffIndicator.length);
                    turnItOff = true;
                }
                //Hide close controller if we are shutting it down
                let closeFunction = turnItOff ? null : this.state.handleCloseModal;

                this.setState(() => ({handleCloseModal: closeFunction, turnItOff: turnItOff}));
                this.showMessage('Maintenance Message', message, 'info')
            }
        }
        catch (e) {
            //Failed to check for the Broadcast message for some reason...
            //Oh well, just means user may see an error mesage if we are doing maintenance
            console.log('Could not get broadcast.');
            console.log(e);
        }
    }

    adobeDataLayer = (toolFeature, toolSubInteraction, spaContext, isPageLoad) => {

        __ANALYTICS_DATA__.pageType = spaContext;
        __ANALYTICS_DATA__.toolFeatureUsed = toolFeature;
        __ANALYTICS_DATA__.toolSubInteraction = toolSubInteraction;
        __ANALYTICS_DATA__.user.tracking.invoiceNum = this.state.invoiceNum;
        __ANALYTICS_DATA__.user.tracking.payerNo = this.state.customerId;
        _satellite.track('toolTracker');

        if (isPageLoad) {
            this.adobeSatelliteTrack();
        }
    }

    adobeSatelliteTrack = () => {
        _satellite.track('pageLoaded');
    }

    //Reset the payment form when session token times out so user does not get cryptic message
    timeItOut = async () => {
        try {
            if (!inPaymentProcessing) {
                this.timesUp();
            }
        }
        catch (e) {
            //The timer did not work for some reason...
            //Oh well, just means user will see an error mesage if they submit after 5 minutes.
            console.log('Could not time out session.');
            console.log(e);
        }
    }

    timesUp = () => {
        this.setState(() => ({shouldResetOnError: true}));
        this.showMessage('Session Timeout', TIMEOUT_ERROR_MSG, 'help')
    }
}