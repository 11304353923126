import React from 'react'
import HelpText from './help/HelpText'

const OutOfSync = () => (
    <div> 
        <div className='zeroTitle'>Payment Pending</div>
        There is currently a payment pending on this invoice.<br/>
        Please allow some time for your payments to be processed before you try again.<br/>
        <br/>
        If this message does not disappear within 48 hours, please contact<HelpText/>.
	</div>
) 

export default OutOfSync