import React from 'react'
import HelpText from './help/HelpText'
import Header from './Header'
import Footer from './Footer';
import Banner from './Banner'

export default class ErrorHandler extends React.Component {
    constructor(props) {
      super(props);
      // Add some default error states
      this.state = {
        error: false,
        info: null,
      };
    }
  
    componentDidCatch(error, info) {
      // Something happened to one of my children, add error to state
      this.setState({
        error: error,
        info: info,
      });
    }
  
    render() {
      if(this.state.error) {
        // Some error was thrown. Let's display something helpful to the user
        return (
            <div>
                <div className='wrapper'>
                    <div className='content'>
                        <Header/>
                        <Banner inCheckout={false}/>
                        <div className='centerContent'>
                            <h2>Oops. Something went wrong.</h2>
                            Please refresh the page and try your operation again.
                            <br/><br/>If the problem persists, please contact <HelpText/>.
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
      }
      // No errors were thrown. As you were.
      return this.props.children;
    }
  }