import React from 'react'
import HelpText from '../help/HelpText'

export const STRIPE_ERROR_TITLE = 'Payment System Issue'

export const StripeConnectError = () => (
    <span>There was an issue connecting to the payment system.
        <br/>Please refresh the page and try again.
        <br/><br/>If the problem persists please contact<HelpText/>.
    </span>
)

export const StripePaymentError = () => (
    <span>There was an issue processing your card information.
        <br/>Please refresh or use a different browser and try again.
        <br/><br/>If the problem persists please contact<HelpText/>.
    </span>
) 

//Messages pertaining to vaidation of credit card data format
export const STRIPE_CARD_ERROR_TYPE = "card_error";
export const STRIPE_CARD_VALIDATION_ERROR_TYPE = "validation_error";
const STRIPE_CARD_VALIDATION_CODE_ZIP = "incomplete_zip";
const STRIPE_CARD_VALIDATION_CODE_CVC = "incomplete_cvc";
const STRIPE_CARD_VALIDATION_CODE_EXP = "incomplete_expiry";
const STRIPE_CARD_VALIDATION_CODE_NUM = "incomplete_number";

const STRIPE_CARD_ERROR_DECLINED = "card_declined";

export const StripeValidationCodes = {
    "STRIPE_CARD_VALIDATION_CODE_ZIP" : STRIPE_CARD_VALIDATION_CODE_ZIP,
    "STRIPE_CARD_VALIDATION_CODE_CVC" : STRIPE_CARD_VALIDATION_CODE_CVC,
    "STRIPE_CARD_VALIDATION_CODE_EXP" : STRIPE_CARD_VALIDATION_CODE_EXP,
    "STRIPE_CARD_VALIDATION_CODE_NUM" : STRIPE_CARD_VALIDATION_CODE_NUM,
}

export const StripeCardErrorCodes = {
    "STRIPE_CARD_ERROR_DECLINED" : STRIPE_CARD_ERROR_DECLINED,
}


//Messages pertaining to error messages from credit card processing
const STRIPE_BAD_CVC_MESSAGE = "Your card's security code is incorrect.";
const STRIPE_CARD_DECLINED = "Your card was declined.";
const STRIPE_INSUFFICIENT_FUNDS = "Your card has insufficient funds.";
const STRIPE_CARD_EXPIRED = "Your card has expired.";
const STRIPE_CARD_INCORRECT = "Your card number is incorrect.";

export const STRIPE_SERVER_SIDE_VALIDATION_ERRORS = [STRIPE_BAD_CVC_MESSAGE, STRIPE_CARD_DECLINED, STRIPE_INSUFFICIENT_FUNDS, STRIPE_CARD_EXPIRED, STRIPE_CARD_INCORRECT]

//Give a cleaner user message based on Stripe validation codes
export const getStripeValidationMessage = (code) => {
    let userMsg;
    switch(code) {
      case StripeValidationCodes.STRIPE_CARD_VALIDATION_CODE_ZIP: 
          userMsg = "Please enter a valid zip code.";
          break;
      case StripeValidationCodes.STRIPE_CARD_VALIDATION_CODE_CVC:
          userMsg = "Please enter a valid CVC code.";
          break;
      case StripeValidationCodes.STRIPE_CARD_VALIDATION_CODE_EXP:
          userMsg = "Please enter a valid expiration date.";
          break;
      case StripeValidationCodes.STRIPE_CARD_VALIDATION_CODE_NUM:
          userMsg = "Please enter a valid credit card number.";
          break;
      default:
          userMsg = "Please enter valid credit card information.";
    }
    return userMsg;
}

export const getStripeCardErrorMessage = (code) => {
    let userMsg;
    switch(code) {
      case StripeCardErrorCodes.STRIPE_CARD_ERROR_DECLINED:
          userMsg = "Your card has been declined.";
          break;
      default:
          userMsg = "Invalid credit card.";
    }
    return userMsg;
}

