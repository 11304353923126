import React from 'react'
import { Overlay } from '@bbnpm/bb-ui-framework';
import Example from './Example'

export default class Help extends React.Component {

    render = () => {
        return (
            <Overlay 
                isOpen = {this.props.showHelp} 
                portalContainerId = 'app'
                closeOnEscKey = {true}
                closeOnOverlayClick = {false}
                showShadow = {true}
                onClose = {this.closeHelp}>
                <Example closeHelp={this.props.closeHelp} />
            </Overlay>
        )
    }

    closeHelp = () => {
        this.props.closeHelp();
    }
}
