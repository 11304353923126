import React from 'react'
import HelpContact from './HelpContact'
import HelpEmail from './HelpEmail'
import HelpPhone from './HelpPhone'
 
/**
 * Cobbled together collection of customer service information for the most common output usage
 **/

const HelpText = () => (
    <span> 
        <HelpContact/>
        <br/>at <strong><HelpPhone/></strong> or email <HelpEmail/>
    </span>
) 

export default HelpText