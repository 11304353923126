import React from 'react'

export default class Search extends React.Component {
    render() {
        return (
            <div className='search'>
                <div className='search__header'>
                    {this.invoiceText()}
                </div>
                <div className='search__separator'></div>
                {this.searchOrDisplay()}
            </div>
        )
    }
    /*Once we complete a successful search, hide the input controls*/
    searchOrDisplay = () => {
        if (!this.props.inInvoice && !this.props.inCheckout) {
            return (
                <div>
                    <form onSubmit={this.onSubmitSearch}>
                        <span className='search__span'>
                            Bill To Number<span className='error'>*</span><br/>
                            <input onChange={this.numbersOnly} type='text' name='customerNum' id='inputCustomerNum'/>
                        </span>
                        <span className='search__span'>
                            Invoice Number<span className='error'>*</span><br/>
                            <input onChange={this.numbersOnly} type='text' name='invoiceNum' id='inputInvoiceNum'/>
                        </span>
                        <span className='search__span'>
                            Invoice Amount<span className='error'>*</span>
                            <input onChange={this.currencyOnly} type='text' name='invoiceAmount' id='inputInvoiceAmount' placeholder='$'/>
                        </span>
                        <span><button>Search</button></span>
                    </form>
                </div>
            )
        }
        else {
            return (
                <div>
                    <span className='search__span'>
                        <div className='search__span--header'>Bill To Number:</div>
                        <span id='billToDisplay'>{this.props.customerNum}</span>
                    </span>
                    <span className='search__span' >
                        <div className='search__span--header'>Invoice Number:</div>
                        <span id='invoiceNumDisplay'>{this.props.invoiceNum}</span>
                    </span>
                    <span className='search__span'>
                        <div className='search__span--header'>Invoice Amount:</div>
                        <span id='invoiceAmountDisplay'>{this.props.invoiceAmount}</span>
                    </span>
                    <span><button onClick={this.props.reset}>Reset</button></span>
                </div>
            )

        }
    }

    invoiceText = () => {
        if (this.props.inInvoice || this.props.inCheckout) {
            return (<span>Invoice Details</span>)
        }
        else {
            return (<span>Invoice Search</span>)
        }
    }

    onSubmitSearch = (e) => {
        e.preventDefault();

        const customerNum = e.target.elements.customerNum.value.trim();
        const invoiceNum = e.target.elements.invoiceNum.value.trim();
        const invoiceAmount = e.target.elements.invoiceAmount.value.trim();
        this.props.search(customerNum, invoiceNum, invoiceAmount);
    }

    numbersOnly = (e) => {
        if (e.target.value != e.target.value.replace(/[^0-9]/g, '')) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }
    }

    currencyOnly = (e) => {
        if (e.target.value != e.target.value.replace(/[^0-9.]/g, '')) {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '');
        }
    }
}