import React from 'react'
import Allowance from './Allowance'
import NumberFormat from 'react-number-format'
import {CURRENCY_REGEX} from './Constants'

const TRACKING_PAGE = 'Invoice';
const TRACKING_OP_AMOUNT = 'Payment Amount';
const TRACKING_OP_PAY_NOW = 'Pay Now';
const TRACKING_OP_PAYMENT_OPTION = 'Payment Option';

export default class Invoice extends React.Component {
    constructor () {
        super();

        this.state = {
            amountError: false,
            errorText: undefined,
            selectedPaymentOption: "full"
        }
    }
    render() {
        return (
            <div className='invoice'>
                <form onSubmit={this.onSubmitPayment}>
                    <div className='search__header'>
                        Payment Amount
                    </div>
                    <div className='search__separator'></div>
                    <div className='invoice__form'>
                        <div className='invoice__form--amounts'>
                            {this.showAllowance()}
                            <div>
                            <label>
                                <input 
                                    className='invoice__form--Radio invoice__form--corrector' 
                                    type='radio' 
                                    name='paymentOption' 
                                    value='full' 
                                    checked={this.state.selectedPaymentOption === "full"}
                                    onChange={this.handlePaymentOption}/>
                                {this.getPayText()}:&nbsp;&nbsp;
                                <strong>
                                    <NumberFormat value={this.props.amountCanPay} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
                                </strong>
                            </label>
                            </div>
                            <div style={{'paddingTop':'10px'}}>
                            <label>
                                <input 
                                    className='invoice__form--Radio' 
                                    type='radio' 
                                    name='paymentOption' 
                                    value='custom' 
                                    checked={this.state.selectedPaymentOption === "custom"}
                                    onChange={this.handlePaymentOption}/>
                                Pay Custom Amount:&nbsp;&nbsp;
                                <input onChange={this.currencyOnly} type='text' name='paymentAmount' id='customAmount' placeholder='$' disabled={this.state.selectedPaymentOption === 'full'}/>
                            </label>
                            </div>
                            {this.state.amountError && <span className='error'>&nbsp;&nbsp;{this.state.errorText}</span>}
                        </div>
                    </div>
                    <button className="invoice__payButton">PAY NOW</button>
                </form>
            </div>
        )
    }
    onSubmitPayment = (e) => {
        e.preventDefault();
        let payment

        this.props.adobeDataLayer(TRACKING_OP_PAYMENT_OPTION, this.state.selectedPaymentOption, TRACKING_PAGE)
        this.props.adobeDataLayer(TRACKING_OP_PAY_NOW, 'click', TRACKING_PAGE)
        
        //If not paying total, need to validate amount
        if (this.state.selectedPaymentOption != 'full') {
            payment = e.target.elements.paymentAmount.value.trim();

            //Check for a valid Dollars and Cents value
            if (!CURRENCY_REGEX.test(payment)) {
                this.setState({amountError: true, errorText: 'Please include dollars and cents, for example, 99.00'})
                this.props.adobeDataLayer(TRACKING_OP_AMOUNT, 'Invalid Currency Format', TRACKING_PAGE)
                return
            }
        
            if (parseFloat(payment) < .5) {
                this.setState({amountError: true, errorText: 'There is a 50 cent minimum for credit card payments.'})
                this.props.adobeDataLayer(TRACKING_OP_AMOUNT, 'Amount Too Low', TRACKING_PAGE)
                return
            }
            if (parseFloat(payment) > parseFloat(this.props.amountCanPay)) {
                this.setState({amountError: true, errorText: this.getOverpayError()})
                this.props.adobeDataLayer(TRACKING_OP_AMOUNT, 'Amount Too High', TRACKING_PAGE)
                return
            }
        }
        else {
            payment = this.props.amountCanPay;
        }
        
        this.props.enterCheckout(payment);
    }

    getOverpayError = () => {
        if (parseFloat(this.props.amountCanPay) < this.props.amountDue) {
            return 'Total Payment amount cannot be more than your Remaining Limit.'
        }
        else {
            return 'Total Payment amount cannot be more than Current Amount Due.'
        }
    }

    handlePaymentOption = (e) => {

        this.setState({
            amountError: false, selectedPaymentOption: e.target.value
        });
        if (e.target.value === 'full') {
            document.getElementById('customAmount').value = '' 
        }
        else {
            document.getElementById("customAmount").focus();
        }
    }

    getPayText = () => {
        if (parseFloat(this.props.amountCanPay) < this.props.amountDue) {
            return <span>Pay Remaining Credit Limit</span>
        }
        return <span>Pay Current Amount Due</span>
    }

    showAllowance = () => {
        if (parseFloat(this.props.amountDue) > this.props.paymentAllowance) {
            return <Allowance amountDue={this.props.amountDue} paymentAllowance={this.props.paymentAllowance}/>
        }
    }

    currencyOnly = (e) => {
        if (e.target.value != e.target.value.replace(/[^0-9.]/g, '')) {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '');
        }
    }
}