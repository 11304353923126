import React from 'react'
import {Dialog, Typography, Button} from '@bbnpm/bb-ui-framework';

export default class Message extends React.Component {

    render = () => {
        return (
            <div>
                <Dialog
                    isOpen={this.props.isOpen}
                    onClose={this.props.lockDialog == true ? null : this.props.handleCloseModal}
                    type={this.props.type}
                    closeOnEscKey={!this.props.lockDialog}
                    closeOnOverlayClick={false}
                    showShadow={true}
                    portalContainerId="app"
                >
                <Dialog.Title
                    onClose={this.props.lockDialog == true ? null : this.props.handleCloseModal}
                    showDelimiter={true}
                >
                    {this.props.title}
                </Dialog.Title>
                <Dialog.Body>
                    <Typography.P>
                        {this.props.message}
                    </Typography.P>
                </Dialog.Body>
                <Dialog.Footer>
                    <div style={{ display: this.props.lockDialog == true ? 'none' : 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        kind="secondary"
                        onClick={this.props.handleCloseModal}
                        style={{ marginRight: 4 }}
                    >
                        OK
                    </Button>
                    </div>
                </Dialog.Footer>
                </Dialog>
            </div>
        )
    }
}