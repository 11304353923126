import React from 'react'
import Search from './Search'
import Questions from './Questions'
import Invoice from './Invoice'
import Checkout from './Checkout'
import ZeroBalance from './ZeroBalance'
import ZeroAllowance from './ZeroAllowance'
import OutOfSync from './OutOfSync'

export default class Controls extends React.Component {
    render() {
        return (
            <div className='centerContent'>
                <div style={{display:'block'}}>
                    <Search
                        /*Functions from PaymentApp*/
                        search = {this.props.search} 
                        inInvoice = {this.props.inInvoice}
                        inCheckout = {this.props.inCheckout}
                        reset = {this.props.reset}
                        /*Search Values*/
                        customerNum = {this.props.customerNum}
                        invoiceNum = {this.props.invoiceNum}
                        invoiceAmount = {this.props.invoiceAmount}
                    />
                    <Questions adobeDataLayer={this.props.adobeDataLayer}/>
                </div>
                {this.showInvoice()}
                {this.showCheckout()}
                
            </div>
        )
    }
    //Shows the invoice after user successfully searches for it
    showInvoice = () => {
        if (this.props.inInvoice) {
            if (this.props.outOfSync) {
                return <OutOfSync/>
            }
            if (this.props.amountDue <= 0) {
                return <ZeroBalance/>
            }
            else if (this.props.amountCanPay <= 0) {
                return <ZeroAllowance paymentAllowance={this.props.paymentAllowance}/>
            }
            return (
                <div>
                    <Invoice 
                        amountDue={this.props.amountDue}
                        paymentAllowance = {this.props.paymentAllowance}
                        amountCanPay = {this.props.amountCanPay}
                        enterCheckout={this.props.enterCheckout}
                        adobeDataLayer = {this.props.adobeDataLayer}
                    />
                </div>
            )
        }
    }
    //Shows the CC checkout after user clicks 'PAY NOW' button
    showCheckout = () => {
        if (this.props.inCheckout) {
            return (
                <Checkout
                    reset = {this.props.reset}
                    paymentAmount = {this.props.paymentAmount}
                    paidInvoice = {this.props.paidInvoice}
                    paid = {this.props.paid}
                    savePaymentMethod = {this.props.savePaymentMethod}
                    showMessage = {this.props.showMessage}
                    makePayment = {this.props.makePayment}
                    furtherProcessing = {this.props.furtherProcessing}
                    adobeDataLayer = {this.props.adobeDataLayer}
                />
            )
        }
    }
}