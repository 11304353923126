import React from 'react';
import logo from '../assets/images/B_IG_Logo_Stack_Rev_RGB.svg';

const Footer = () => {
  return (
    <div className="footer footerwrap">
      <footer>
        <table className="table-borderless" className="tableStyle">
          <tbody>
            <tr className="flex-column flex-grow" style={{ height: '129px' }}>
              <td>
                <table className="innerTableStyle">
                  <tbody>
                    <tr
                      valign="top"
                      className="footer_links"
                      style={{ align: 'left' }}
                    >
                      <td className="tdStyle">
                        <div>
                          <a
                            className="footerlinks"
                            href="https://www.bloombergindustry.com/"
                            className="altLinkStyle"
                          >
                            <img
                              src={logo}
                              alt="Bloomberg Industry Group"
                              style={{ height: '60px', width: '260px' }}
                            />
                          </a>
                        </div>
                      </td>
                      <td className="hide-mobile" style={{ width: '33.33%' }}>
                        MORE INFORMATION
                        <br />
                        <hr className="footer_line" />
                        <font style={{ fontWeight: 'normal' }}>
                          <a
                            className="footerlinks"
                            href="https://www.bloombergindustry.com/about-us/"
                          >
                            About Us
                          </a>
                        </font>
                        <br />
                        <font style={{ fontWeight: 'normal' }}>
                          <a
                            className="footerlinks"
                            href="https://www.bloombergindustry.com/media/"
                          >
                            Media
                          </a>
                        </font>
                        <br />
                        <font style={{ fontWeight: 'normal' }}>
                          <a
                            className="footerlinks"
                            href="https://www.bloombergindustry.com/contact-us/"
                          >
                            Contact Us
                          </a>
                        </font>
                      </td>
                      <td className="hide-mobile" style={{ width: '33.33%' }}>
                        24/7 HELP DESK
                        <br />
                        <hr className="footer_line" />{' '}
                        <font style={{ fontWeight: 'normal' }}>
                          888.560.2529
                        </font>
                        <br />
                        <font style={{ fontWeight: 'normal' }}>
                          <a
                            className="footerlinks"
                            href="mailto:help@bloomberglaw.com"
                          >
                            help@bloomberglaw.com
                          </a>
                        </font>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="flex-column flex-grow" className="flexColumnStyle">
              <td>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr className="footer_bottom_links">
                      <td style={{ width: '10%' }}>&nbsp;</td>
                      <td style={{ width: '12%' }}>
                        <a
                          className="footerlinks"
                          href="https://www.bloombergindustry.com/terms-and-conditions/"
                        >
                          Terms of Service
                        </a>
                      </td>
                      <td style={{ width: '12%' }}>
                        <a
                          className="footerlinks"
                          href="https://www.bloombergindustry.com/privacy-policy/"
                        >
                          Privacy Policy
                        </a>
                      </td>
                      <td style={{ width: '12%' }}>
                        <a
                          className="footerlinks"
                          href="https://www.bloombergindustry.com/accessibility/"
                        >
                          Accessibility
                        </a>
                      </td>
                      <td style={{ width: '33%' }}>
                        &copy; {new Date().getFullYear()} Bloomberg Industry
                        Group, Inc. All Rights Reserved
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </footer>
    </div>
  );
};
export default Footer;
