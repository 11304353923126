import React from 'react'
import NumberFormat from 'react-number-format'
import {Flyout, Icon} from '@bbnpm/bb-ui-framework';

export default class Search extends React.Component {
    render() {
        return (
            <div>
                <div className='allowance__currentAmount'>
                    Current Amount Due:&nbsp;&nbsp;
                    <strong>
                        <NumberFormat value={this.props.amountDue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
                    </strong>
                </div>
                <div className='allowance__creditAmount'>
                    Available Credit Payment Limit:&nbsp;&nbsp;
                    <strong>
                        <NumberFormat value={this.props.paymentAllowance} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
                    </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='allowance__question'>
                        {this.getMessage()}
                    </span>
                </div>
            </div>
        )
    }

    getMessage = () => {
        const paid = 50000 - this.props.paymentAllowance;
        return (
            <Flyout
                maxWidth={800}
                content={
                    <div>
                        Total payments are limited to <span className='allowance__question'>$50,000</span> per calendar year per customer.<br/>
                        This customer has already paid&nbsp;
                        <span className='allowance__question'>
                            <NumberFormat value={paid} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
                        </span>
                        <br/><br/>
                        Please <a target='_blank' href='https://www.bloombergindustry.com/contact-us/'>Contact Customer Support</a>&nbsp;
                        If you would like to make a payment<br/>above your remaining credit card payment limit.
                    </div>
                }
                trigger="click mouseenter">
                    <Icon name="question-circle-outline"/>&nbsp;Why is my payment limit less than the invoice amount?
            </Flyout>
        )
    }
} 