import React from 'react'
import HelpText from './help/HelpText'

const ZeroAllowance = () => {
    return (
        <div> 
            <div className='zeroTitle'>Maximum Credit Card Payment Allowance Reached</div>
            Total payments are limited to <span className='allowance__question'>$50,000</span> per calendar year per customer.<br/>
            This customer's payments have already exceeded that amount.
            <br/><br/>
            If you would like to make a payment above your remaining credit card
           <br/>payment limit, please contact <HelpText/>.
            
        </div>
    )
}

export default ZeroAllowance