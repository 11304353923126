import React from 'react'
import Help from './Help'

export default class Questions extends React.Component {
    state = {
        showHelp: false
    }

    render() {
        return (
            <div className='questions'>
                <Help showHelp={this.state.showHelp} closeHelp={this.closeHelp}/>
                <div className='questions__info single-column-hide'>
                    <div className='questions__info--header'>Questions?</div>
                    WE CAN HELP
                    <div className='questions__info--separator'></div>
                    <div className='questions__info--links'>
                        <a href='#' onClick={this.showHelp}>Find Your Bill To/Invoice Number</a>
                        <br/>
                        <a target='_blank' href='https://www.bloombergindustry.com/contact-us/'>Contact Customer Support</a>
                    </div> 
                </div>
                <div className='questions__info--links single-column-show'>
                    <a target='_blank' href='https://www.bloombergindustry.com/contact-us/'>Contact Customer Support</a><br/><br/>
                </div>
            </div>
        )
    }

    showHelp = () => {
        this.setState({showHelp: true});
        this.props.adobeDataLayer('Invoice Help', 'Open invoice help', 'Invoice Help', true);
    }

    closeHelp = () => {
        this.setState({showHelp: false});
    }
}
