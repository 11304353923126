import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import PaymentApp from './components/PaymentApp'
import 'normalize.css/normalize.css'
import './styles/styles.scss'
import {ThemeProvider} from '@bbnpm/bb-ui-framework';
import ErrorHandler from './components/ErrorHandler'

/** Application entry point for payment app **/

class App extends React.Component {
    render() {
        return (<ErrorHandler><PaymentApp/></ErrorHandler>);
    }
}

ReactDOM.render(<ThemeProvider><App/></ThemeProvider>, app);