import React from 'react';
import invoice from '../assets/images/invoice.jpg';
import HelpText from './help/HelpText';
import { Helmet } from 'react-helmet';

/**
 * This is the component that displays the Invoice Search Help page (i.e. example invoice image)
 */

export default class Example extends React.Component {

	render() {
        return (
			<div className='example'>
				<div className='example__header'>
					<h2>Invoice Search Help</h2>
					<button className="checkout__button" onClick={this.closeWindow}>Close Help</button>
				</div>
				<p>Please refer to the diagram below to locate your Bill To Number, Invoice Number and Invoice Amount.&nbsp; <br/>
					<strong>All three numbers are required to make an online payment.</strong>
				</p>
				<p>If you do not have all three numbers, please contact<HelpText/>.</p>

				<img src={invoice} alt="" width="979" border="0" id="Picture_x0020_2"/>
				<script type="text/javascript">_satellite.pageBottom();</script>
			</div>
		)
	}

	closeWindow = () => {
		this.props.closeHelp();
	}
}