import React from 'react';
import  { StripeConnectError, StripePaymentError, getStripeValidationMessage, getStripeCardErrorMessage,
          STRIPE_ERROR_TITLE, STRIPE_SERVER_SIDE_VALIDATION_ERRORS, STRIPE_CARD_VALIDATION_ERROR_TYPE, STRIPE_CARD_ERROR_TYPE
        } from './messages/StripeErrors'
import {LAMBDA_PAYMENT_SUCCESS, LAMBDA_PAYMENT_REQUIRES_FURTHER_VALIDATION, LAMBDA_PAYMENT_SUCCESS_APPLY_FAIL, MESSAGE_TOKEN_TIME_OUT} from './Constants'
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import NumberFormat from 'react-number-format'

import validator from 'validator'

const TRACKING_PAGE = 'Checkout';
const TRACKING_OP_VALIDATION = 'Checkout Validation';
const TRACKING_OP_PAYMENT_METHOD = 'Create PaymentMethod';
const TRACKING_OP_MAKE_PAYMENT = 'Submit Payment';

//Style Class to be passed into Stripe Elements
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

//Display the Stripe Element component
export class Checkout extends React.Component {
  constructor(){
    super();

    this.state = {
       email: undefined,
       emailError: false,
       name: undefined,
       nameError: false,
       cardErrorText: undefined,
       validated: false
    }
  }

  render = () => {
    const {stripe} = this.props;
    
    if (stripe) {
      return (
        <div className="checkout">
          <form onSubmit={this.blockAndPay}>
                <div className='search__header'>
                    Pay with card
                </div>
                <div className='search__separator'></div>
                
                <div className='checkout__span'>
                  Email
                  <input disabled={this.props.paid} onBlur={this.validateEmail} type='text' name='email' id='inputEmail' className='checkout__span--input' maxLength="320"/>
                  {this.state.emailError  && <span className='error'>Please enter a valid email.</span>}
                </div>

                <div>
                  Name on Card
                  <div className='checkout__span'>
                    <input disabled={this.props.paid} onBlur={this.validateName} type='text' name='name' id='inputName' className='checkout__span--input' maxLength="60"/>
                    {this.state.nameError && <span className='error'>Please enter a valid name.</span>}
                  </div>
                </div>

                <div className='checkout__span'>
                  Card Information
                  <div className='checkout__element'>
                    <CardElement options={CARD_ELEMENT_OPTIONS} onChange={this.resetValidate}/>
                  </div>
                  <div className={this.showCover()}>
                  </div>
                  {this.state.cardErrorText && <div className='error' style={{'paddingTop': '3px'}}>{this.state.cardErrorText}</div>}
                </div>

                <div className='checkout__buttonHolder'>
                    <button disabled={this.props.paid || !stripe} className="checkout__button">{this.buttonLabel()}</button>
                    {this.props.paid && <span style={{'marginLeft':'50px'}}><button onClick={this.props.reset}>Pay Another Invoice</button></span>}
                </div>
            </form>
          </div>
        )
    }
    return null
  }

  componentDidMount () {
    if (!this.props.stripe) {
      this.props.showMessage(STRIPE_ERROR_TITLE, <StripeConnectError/>, 'error');
    }
  }

  //Using a global variable to block multi-submits because state is async & quick clicks can get processed before state changes.
  //Flipping the variable is most granular/reliable prevention.
  blockAndPay = (e) => {
    try {
        e.preventDefault();

        if (PAY_CLICKED) {
          return;
        }
        PAY_CLICKED = true;
        this.payInvoice();
    }
    catch (ex) {
        const logMsg = 'Code Exception: ' + ex;
        console.log(logMsg);
        this.props.adobeDataLayer(TRACKING_OP_MAKE_PAYMENT, logMsg, TRACKING_PAGE);
        this.props.showMessage(STRIPE_ERROR_TITLE, <StripePaymentError/>, 'error');
        PAY_CLICKED = false;
    }
  }

  payInvoice = async () => {
    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      this.props.adobeDataLayer(TRACKING_OP_MAKE_PAYMENT, "Stripe Not Initialized", TRACKING_PAGE);
      this.props.showMessage(STRIPE_ERROR_TITLE, <StripeConnectError/>, 'error');
      PAY_CLICKED = false;
      return;
    }
    
    if (this.validateForm()) {
      try {
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
              // Include any additional collected billing details.
              name: this.state.name,
              email: this.state.email
            },
        });

        console.log('result from createPaymentMethod: ', result)

        if (result.error) {
          console.log('Could not create Payment Method: ' + result.error);
          PAY_CLICKED = false;

          //TODO: Can combine these next two error checks by only checking error.code rather than error.type
          if (result.error.type == STRIPE_CARD_VALIDATION_ERROR_TYPE) {
              const errorMsg = getStripeValidationMessage(result.error.code);
              this.setState({cardErrorText: errorMsg});
              this.props.adobeDataLayer(TRACKING_OP_PAYMENT_METHOD, errorMsg, TRACKING_PAGE);
              return;
          }
          if (result.error.type == STRIPE_CARD_ERROR_TYPE) {
              const errorMsg = getStripeCardErrorMessage(result.error.code);
              this.setState({cardErrorText: errorMsg});
              this.props.adobeDataLayer(TRACKING_OP_PAYMENT_METHOD, errorMsg, TRACKING_PAGE);
              return;
          }
          this.props.adobeDataLayer(TRACKING_OP_PAYMENT_METHOD, 'Failed to create Stripe payment method', TRACKING_PAGE);
          this.props.showMessage(STRIPE_ERROR_TITLE, <StripePaymentError/>, 'error');
          return;
        }

        const makePaymentResult = await this.props.makePayment(result.paymentMethod.id, null, this.state.email, this.state.name)
        console.log("makePaymentResult: ", makePaymentResult)

        if (makePaymentResult.status == LAMBDA_PAYMENT_SUCCESS || makePaymentResult.status == LAMBDA_PAYMENT_SUCCESS_APPLY_FAIL) {
          this.props.adobeDataLayer(TRACKING_OP_MAKE_PAYMENT, 'Success', TRACKING_PAGE);
          this.props.paidInvoice()
        }
        else if (makePaymentResult.status == LAMBDA_PAYMENT_REQUIRES_FURTHER_VALIDATION) {
          this.props.adobeDataLayer(TRACKING_OP_MAKE_PAYMENT, "Payment Requires Action", TRACKING_PAGE);
          this.props.savePaymentMethod(result.paymentMethod.id)
          console.log('Begin secondary card auth process')
          this.props.furtherProcessing(makePaymentResult.payment_intent_client_secret)
        }
        else {
          PAY_CLICKED = false;
          if (STRIPE_SERVER_SIDE_VALIDATION_ERRORS.includes(makePaymentResult.message)) {
            this.props.adobeDataLayer(TRACKING_OP_MAKE_PAYMENT, makePaymentResult.message, TRACKING_PAGE);
            this.setState({cardErrorText: makePaymentResult.message});
          }
          else if (makePaymentResult.message == MESSAGE_TOKEN_TIME_OUT) {
            //The timeOut method called inside makePayment will take of showing a message and resetting
            return;
          }
          else {
            console.log('Could not process payment: ' + makePaymentResult.message)
            this.props.adobeDataLayer(TRACKING_OP_MAKE_PAYMENT, "Make Payment Failed", TRACKING_PAGE);
            this.props.showMessage(STRIPE_ERROR_TITLE, <StripePaymentError/>, 'error');
          }
        } 
      }
      catch(e) {
        console.log('Credit Card Processing Error: ' + e);
        this.props.adobeDataLayer(TRACKING_OP_MAKE_PAYMENT, 'Checkout Exception', TRACKING_PAGE);
        this.props.showMessage(STRIPE_ERROR_TITLE, <StripePaymentError/>, 'error');
        PAY_CLICKED = false;
      }
    }
    else {
      PAY_CLICKED = false;
    }
  }

  validateEmail = (e) => {
    const inputEmail = e.target.value.trim();

    if (this.isValidEmail(inputEmail)) {
      this.setState({emailError: false, email: inputEmail})
    }
    else {
      this.setState({emailError: true, email: undefined})
    }
  }

  validateName = (e) => {
    const inputName = e.target.value.trim();
  
    if (this.isValidName(inputName)) {
      this.setState({nameError: false, name: inputName})
    }
    else {
      this.setState({nameError: true, name: undefined})
    }
  }

  resetValidate = () => {
    this.setState({cardErrorText: undefined})
  }

  validateForm = () => {

    let validated = true;
    try {
        this.resetValidate();

        const noCard = document.getElementsByClassName("StripeElement--empty").length > 0;

        let trackingError = "";

        if (noCard) { 
          this.setState({cardErrorText: 'Please enter credit card info.'})
          trackingError += '-No card';
          validated = false;
        }
        if (!this.state.email || !this.isValidEmail(this.state.email)) {
          this.setState({emailError: true})
          trackingError += '-Invalid email';
          validated = false
        }

        if (!this.isValidName(this.state.name)) {
          this.setState({nameError: true})
          trackingError += '-Invalid name';
          validated = false
        }
        
        if (validated) {
          this.props.adobeDataLayer(TRACKING_OP_VALIDATION, 'Card info validated',TRACKING_PAGE);
        }
        else {
          this.props.adobeDataLayer(TRACKING_OP_VALIDATION, trackingError, TRACKING_PAGE);
        }
    }
    catch (e) {
        const logMsg = 'Validation Exception: ' + e;
        console.log(logMsg);
        this.props.adobeDataLayer(TRACKING_OP_VALIDATION, logMsg, TRACKING_PAGE);
        this.props.showMessage(STRIPE_ERROR_TITLE, <StripePaymentError/>, 'error');
        return false;
    }
    return validated
  }

  showCover = () => {
    if (this.props.paid) {
      return "cover"
    }
    return "cover cover_hidden"
  }

  buttonLabel = () => {
    if (this.props.paid) {
    return <span>PAID&nbsp;&nbsp;
              <NumberFormat value={this.props.paymentAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
            </span>
    }
    return (<span>Pay&nbsp;&nbsp;
              <NumberFormat value={this.props.paymentAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
            </span>)
  }

  //Validation methods
  isValidEmail = (email) => validator.isEmail(email)
  
  isValidName = (name) => {
    //Must be specified
    if (!name) {
      return false;
    }
    
    //Check for reasonable name length
    const len = name.length;
    if (len < 2 || len > 60) {
      return false;
    } 

    return true;
  }
}

//Inject Stripe components into the checkout form
export default class InjectedCheckout extends React.Component {
  render = () => {
    return (
        <ElementsConsumer>
          {({stripe, elements}) => (
            <Checkout  
              stripe={stripe}
              elements={elements}
              paidInvoice={this.props.paidInvoice}
              paid={this.props.paid}
              reset = {this.props.reset}
              paymentAmount = {this.props.paymentAmount}
              paymentMethod = {this.props.paymentMethod}
              savePaymentMethod = {this.props.savePaymentMethod}
              showMessage = {this.props.showMessage} 
              makePayment = {this.props.makePayment} 
              furtherProcessing = {this.props.furtherProcessing}
              adobeDataLayer = {this.props.adobeDataLayer} />
          )}
        </ElementsConsumer>
    )
  }
}